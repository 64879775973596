import "core-js/modules/es.date.to-json.js";
import { hitEventTms } from "integration-web-core--socle/js/assets/commons/_tms.js";
export function addTmsTrackingForChooseMyStoreButton(buttonName, buttonType, buttonLocation) {
  var eventData = {
    "button_name": buttonName,
    "button_type": buttonType
  };
  if (buttonLocation) eventData.button_location = buttonLocation;
  hitEventTms(window, 'cdl_event', JSON.stringify({
    'event_name': 'button.click',
    'event_data': eventData
  }));
}