import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.date.to-json.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/web.timers.js";
import { EVENT_TYPE } from "../../../storecontextlayer/enums/event-type";
import { geolocateUser } from "./geolocation";
import { onInputNavigation, onPredictionsNavigation } from "./autocomplete/navigation";
import { onInputSearch } from "./autocomplete/search";
import { fetchStorePredictionsService } from "../../../storecontextlayer/services/store-context-service";
import { clearErrorMessage, insertErrorMessage } from "./errors";
import { renderStorePredictions } from "./autocomplete/render";
import { applyTrackingAndContextCookieOnStoreSearchBlock, initStoreSearchResultData, integrateHtmlToStoreSearchBlock } from "../store-search-result/store-search-result";
import { hitEventTms } from "integration-web-core--socle/js/assets/commons/_tms.js";
import { initDecreasedViewListener } from "./decreased-store-search";
import { customerContextCity, customerContextPostcode } from "../../customercontextlayer.src";
import { addTmsTrackingForChooseMyStoreButton } from "../../../common/tracking-tms";
import { fetchTrackingCookieService } from "../../../common/services/tracking-cookie";
var STORE_SEARCH_RESULTS = 'store-search-results';
var STORE_SEARCH = 'store-search';
var STORE_SEARCH_LOADER = 'store-search-loader';
var SEARCH_INPUT_NAME = 'customerCentricSearchInput';
var SEARCH_PREDICTIONS = 'search-predictions';
export var STORE_CONTEXT_LAYER_URL = '/store-header-module/services/customercontextlayer';
var ID_SELECTOR = 'data-selectorid';
var storeContextLayerBlock = document.querySelector("[".concat(ID_SELECTOR, "=\"component-customer-context-layer\"]"));
var searchPredictionsBlock = storeContextLayerBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(SEARCH_PREDICTIONS, "\"]"));
var storeSearchBlock = storeContextLayerBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH, "\"]"));
var storeSearchLoaderBlock = storeSearchBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH_LOADER, "\"]"));
var storeSearchInputBlock = storeSearchBlock.querySelector("input[name=\"".concat(SEARCH_INPUT_NAME, "\"]"));
var storeSearchResultBlock = storeSearchBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH_RESULTS, "\"]"));
var localitiesCountry = storeContextLayerBlock.dataset.localitiescountry;
var timeoutID = 0;
function sendCdlEventOnResultDisplay() {
  hitEventTms(window, 'cdl_event', JSON.stringify({
    'event_name': 'step.display',
    'event_data': {
      'feature_name': 'header',
      'step_name': 'store result',
      'funnel_name': 'contextualization layer'
    }
  }));
}
export function initStoreContextSearch() {
  var geolocateButton = storeContextLayerBlock.querySelector("[".concat(ID_SELECTOR, "=\"user-locator-button\"]"));
  geolocateButton.addEventListener(EVENT_TYPE.CLICK, geolocateUser.bind(this, storeSearchBlock, storeSearchResultBlock));
  loadTrackingLayerEvents();
  storeSearchInputBlock.addEventListener(EVENT_TYPE.INPUT, function (event) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(function () {
      var input = storeSearchInputBlock.value;
      if (input === 0) {
        searchPredictionsBlock.style.display = 'none';
      } else {
        generateStorePredictions(input);
      }
    }, 250, event);
  }.bind(this));
  var storeSearchForm = storeContextLayerBlock.querySelector("[".concat(ID_SELECTOR, "=\"store-search-form\"]"));
  storeSearchForm.addEventListener(EVENT_TYPE.SUBMIT, function (event) {
    event.preventDefault();
    return false;
  });
  document.addEventListener(EVENT_TYPE.CLICK, function (event) {
    if (event.target !== storeSearchInputBlock && event.target !== searchPredictionsBlock) {
      cleanStoreSearchPredictions();
    }
  });
  storeSearchInputBlock.addEventListener(EVENT_TYPE.FOCUS, function () {
    return generateStorePredictions(storeSearchInputBlock.value).then(function () {
      var firstSearchElem = searchPredictionsBlock.querySelector("li:first-child");
      firstSearchElem && firstSearchElem.classList.add('js-focus-prediction');
    });
  });
  storeSearchInputBlock.addEventListener(EVENT_TYPE.KEYDOWN, function (event) {
    return onInputNavigation(event, searchPredictionsBlock);
  });
  storeSearchInputBlock.addEventListener(EVENT_TYPE.KEYPRESS, function (event) {
    return onInputSearch(event, storeSearchInputBlock);
  });
  searchPredictionsBlock.addEventListener(EVENT_TYPE.KEYDOWN, function (event) {
    return onPredictionsNavigation(event, searchPredictionsBlock);
  });
  initStoreSearchResultData(storeContextLayerBlock);
  searchPredictionsBlock.style.display = 'none';
}
export function cleanStoreSearchPredictions() {
  if (searchPredictionsBlock.style.display !== 'none') {
    searchPredictionsBlock.style.display = 'none';
    searchPredictionsBlock.innerHTML = '';
  }
}
export function onPredictionSelection(locality) {
  cleanStoreSearchPredictions();
  storeSearchInputBlock.value = locality.description;
  if (locality.location) {
    fetchStoresAroundRendering(locality.location.lat, locality.location.lng).then(function (result) {
      sendCdlEventOnResultDisplay();
      integrateHtmlToStoreSearchBlock(result, storeSearchBlock);
      applyTrackingAndContextCookieOnStoreSearchBlock(storeSearchBlock, locality.location.lat, locality.location.lng);
    });
  }
  if (locality.type === 'locality') {
    customerContextPostcode = locality.postal_codes && locality.postal_codes.length > 0 ? locality.postal_codes[0] : '';
    customerContextCity = locality.name;
  } else if (locality.type === 'postal_code') {
    customerContextPostcode = locality.name;
    customerContextCity = locality.postal_town;
  }
}
function loadTrackingLayerEvents() {
  var cookieUsageDescriptionLink = storeSearchResultBlock.querySelector("[".concat(ID_SELECTOR, "=\"a-cookie-usage-description-link\"]"));
  cookieUsageDescriptionLink === null || cookieUsageDescriptionLink === void 0 || cookieUsageDescriptionLink.addEventListener(EVENT_TYPE.CLICK, function () {
    var buttonLocationValue = "context-layer";
    fetchTrackingCookieService(buttonLocationValue, function () {
      addTmsTrackingForChooseMyStoreButton("more information about cookies", "Contextualisation", buttonLocationValue);
    });
  }.bind(this));
}
function generateStorePredictions(input) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    fetchStorePredictionsService(input, localitiesCountry, function (predictions) {
      clearErrorMessage(storeSearchBlock);
      predictions = JSON.parse(predictions || '{}');
      if ("localities" in predictions && predictions.localities.length > 0) {
        renderStorePredictions.call(_this, predictions.localities, searchPredictionsBlock);
        storeSearchInputBlock.focus();
      } else {
        searchPredictionsBlock.style.display = 'none';
      }
      resolve();
    }, function (err) {
      insertErrorMessage(storeSearchBlock, getStoreSearchErrorMessage());
      initDecreasedViewListener();
      reject(err);
    });
  });
}
export function fetchStoresAroundRendering(latitude, longitude, productRef) {
  storeSearchLoaderBlock.style.display = 'block';
  return new Promise(function (resolve, reject) {
    fetchStoresAroundRenderingService(latitude, longitude, productRef, function (resultTemplate) {
      clearErrorMessage(storeSearchBlock);
      resolve(resultTemplate);
    }, function (err) {
      storeSearchResultBlock.innerHTML = '';
      insertErrorMessage(storeSearchBlock, getStoreSearchErrorMessage());
      initDecreasedViewListener();
      reject(err);
    }, function () {
      storeSearchLoaderBlock.style.display = 'none';
    });
  });
}
export function getStoreSearchErrorMessage() {
  return storeheader.i18n.store_search_error;
}
export function getGeolocateErrorMessage(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return storeheader.i18n.geolocate_permission_error;
    case error.POSITION_UNAVAILABLE:
      return storeheader.i18n.geolocate_position_error;
    case error.TIMEOUT:
      return storeheader.i18n.geolocate_timeout_error;
    default:
      return storeheader.i18n.geolocate_unknown_error;
  }
}
export function fetchStoresAroundRenderingService(latitude, longitude, productRef, successCallback, errorCallback, completeCallback) {
  var data = {
    latitude: latitude,
    longitude: longitude
  };
  if (productRef) data.productRef = productRef;
  lm.fetch({
    url: STORE_CONTEXT_LAYER_URL + '/store-search-result',
    data: data,
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'text/html',
      'Content-Type': 'text/html; charset=UTF-8',
      'Cache': 'no-cache',
      'Access-Control-Allow-Origin': '*'
    },
    success: successCallback,
    error: errorCallback,
    complete: completeCallback
  });
}