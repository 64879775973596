import { EVENT_TYPE } from "../storecontextlayer/enums/event-type";
import { fetchTrackingCookieService } from "./services/tracking-cookie";
import { addTmsTrackingForChooseMyStoreButton } from "./tracking-tms";
export function addContextBannerEvent() {
  var contextBannerSelector = document.querySelector("[data-selectorid='context-banner']");
  var postcodeBannerSelector = contextBannerSelector === null || contextBannerSelector === void 0 ? void 0 : contextBannerSelector.querySelector(".js-context-postcode-button");
  postcodeBannerSelector === null || postcodeBannerSelector === void 0 || postcodeBannerSelector.addEventListener(EVENT_TYPE.CLICK, function () {
    var openContextLayerEvent = new Event("openStoreContextLayer");
    var buttonLocationValue = "Header";
    fetchTrackingCookieService(buttonLocationValue, function () {
      addTmsTrackingForChooseMyStoreButton("Choose my store", "Contextualisation", buttonLocationValue);
    });
    window.dispatchEvent(openContextLayerEvent);
  });
}