import { EVENT_TYPE } from "../storecontextlayer/enums/event-type";
import { addTmsTrackingForChooseMyStoreButton } from "./tracking-tms";
import { fetchTrackingCookieService } from "./services/tracking-cookie";
export function addContextHeaderEvent() {
  var contextHeaderSelector = document.querySelector("[data-selectorid='context-header']");
  var storeHeaderSelector = contextHeaderSelector.querySelector(".js-context-store-name-button");
  var openContextLayerEvent = new Event("openStoreContextLayer");
  storeHeaderSelector.addEventListener(EVENT_TYPE.CLICK, function () {
    var buttonLocationValue = "Header";
    fetchTrackingCookieService(buttonLocationValue, function () {
      addTmsTrackingForChooseMyStoreButton("Choose my store", "Contextualisation", buttonLocationValue);
    });
    window.dispatchEvent(openContextLayerEvent);
  });
}