import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { getStoreSearchErrorMessage, STORE_CONTEXT_LAYER_URL } from "./store-context-search";
import { insertErrorMessage } from "./errors";
import { EVENT_TYPE } from "../../../storecontextlayer/enums/event-type";
import { applyTrackingAndContextCookieOnStoreSearchBlock, applyTrackingAndContextCookieOnStoreSearchBlockWithoutCoordinates } from "../store-search-result/store-search-result";
var ID_SELECTOR = 'data-selectorid';
var STORE_SEARCH = 'store-search';
var STORE_SEARCH_DECREASED = 'store-search-decreased';
var STORE_SEARCH_DECREASED_BUTTON = 'store-search-decreased-button';
var STORE_SEARCH_DECREASED_LOADER = 'store-search-decreased-loader';
var SEARCH_DECREASED_DROPDOWN_NAME = 'storeSearchRegion';
var STORE_SEARCH_DECREASED_RESULTS = 'store-decreased-results';
var KL_HIDDEN_CLASS = 'kl-hidden';
var KL_VISIBLE_CLASS = 'kl-visible';
var storeContextLayerBlock = document.querySelector("[".concat(ID_SELECTOR, "=\"component-customer-context-layer\"]"));
var storeSearchBlock = storeContextLayerBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH, "\"]"));
var storeSearchDecreasedButtonBlock = storeSearchBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH_DECREASED_BUTTON, "\"]"));
var storeSearchDecreasedLoaderBlock = storeSearchBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH_DECREASED_LOADER, "\"]"));
export function initDecreasedViewListener() {
  storeSearchDecreasedButtonBlock.addEventListener(EVENT_TYPE.CLICK, function () {
    fetchStoresDecreased(handleSuccess, handleError);
  });
}
export function fetchStoresDecreased(successCallback, errorCallback) {
  storeSearchDecreasedLoaderBlock.style.display = 'block';
  lm.fetch({
    url: STORE_CONTEXT_LAYER_URL + '/decreased-view',
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'text/html',
      'Content-Type': 'text/html; charset=UTF-8',
      'Cache': 'no-cache',
      'Access-Control-Allow-Origin': '*'
    },
    success: successCallback,
    error: errorCallback
  });
}
function handleSuccess(response) {
  storeSearchBlock.innerHTML = response;
  // TODO handle differently the coordinats for decreased view (lat, long and postcode will be those from the selected store)
  applyTrackingAndContextCookieOnStoreSearchBlockWithoutCoordinates(storeSearchBlock);
  filterByRegion();
}
function handleError() {
  storeSearchDecreasedButtonBlock.classList.add('kl-hidden');
  insertErrorMessage(storeSearchBlock, getStoreSearchErrorMessage());
}
function filterByRegion() {
  var storeSearchDecreasedBlock = document.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH_DECREASED, "\"]"));
  var storeSearchDecreasedDropdownBlock = storeSearchDecreasedBlock.querySelector("select[name=\"".concat(SEARCH_DECREASED_DROPDOWN_NAME, "\"]"));
  storeSearchDecreasedDropdownBlock.addEventListener(EVENT_TYPE.CHANGE, handleRegionChange);
}
function handleRegionChange() {
  var selectedRegion = this.value;
  var storeSearchDecreasedBlock = document.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH_DECREASED, "\"]"));
  var storeSearchDecreasedResultsBlock = storeSearchDecreasedBlock.querySelectorAll("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH_DECREASED_RESULTS, "\"] > li"));
  storeSearchDecreasedResultsBlock.forEach(function (store) {
    var storeRegion = store.getAttribute('data-region');
    handleStoreVisibility(store, storeRegion === selectedRegion);
  });
}
function handleStoreVisibility(store, isVisible) {
  store.classList.remove(isVisible ? KL_HIDDEN_CLASS : KL_VISIBLE_CLASS);
  store.classList.add(isVisible ? KL_VISIBLE_CLASS : KL_HIDDEN_CLASS);
}